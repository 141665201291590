import {useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Divider, List, ListItem, ListItemText, SwipeableDrawer} from "@mui/material";

import {logout} from "../../slices/UserSlice";
import {ROUTES} from "../../utilities/routes";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

import "./navigation.scss";

const NavigationPanel = ({children}) => {
  const [open, setOpen] = useState(false);

  const routs = {
    // Зразки: ROUTES.SAMPLES_INFO,
    "У мене в підзвіті": ROUTES.SKU_LIST,
    "фото облік": ROUTES.SAMPLE_IMAGE_SCANER,
  };

  const list = () => (
    <div
      role="presentation"
      className={"navigationPanel"}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {Object.keys(routs).map(title => (
          <CustomLink key={title} text={title} to={routs[title]} />
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <LanguageSwitch />
        </ListItem>
        <Divider />
        <ExitButton />
      </List>
    </div>
  );

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setOpen(open);
  };
  return (
    <>
      <div onClick={toggleDrawer(true)}>{children}</div>
      <SwipeableDrawer anchor={"left"} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        {list()}
      </SwipeableDrawer>
    </>
  );
};

const CustomLink = props => {
  return (
    <ListItem key={props.text}>
      <Link to={props.to}>
        <ListItemText primary={props.text} />
      </Link>
    </ListItem>
  );
};

const ExitButton = () => {
  const dispatch = useDispatch();

  return (
    <ListItem key={2}>
      <ListItemText primary={"Вихід"} onClick={() => dispatch(logout())} />
    </ListItem>
  );
};

export default NavigationPanel;
