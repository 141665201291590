import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import moment from "moment";

import {apiUrl} from "../api/baseURL";
import {fetchData} from "../api/utils/axios.hook";
import {getError, getInfo} from "../utilities/toasts";

const initialState = {
  currentSample: null,
  current: null,
  mySamples: [],
  tableLoading: false,
  locationHistory: [],
};

export const getSample = createAsyncThunk("sample/getSample", async sampleId => {
  const responce = fetchData(`/samples/get/${sampleId}`, "get");
  return responce;
});

export const fetchByQR = createAsyncThunk("sample/fetchByQR", async url => {
  var croppedUrl = url.replace(apiUrl, "");
  return fetchData(croppedUrl, "get");
});

export const takeUnderReport = createAsyncThunk("sample/takeUnderReport", async ({sampleId, data}) => {
  return fetchData(`/samples/takeUnderReport/${sampleId}`, "patch", data);
});

export const takeKitUnderReport = createAsyncThunk("sample/takeKitUnderReport", async ({kitId, data}) => {
  return fetchData(`/kits/takeUnderReport/${kitId}`, "patch", data);
});

export const returnToStock = createAsyncThunk("sample/returnToStock", async sampleId => {
  return fetchData(`/samples/returnToStock/${sampleId}`, "patch");
});
export const returnKitToStock = createAsyncThunk("sample/returnKitToStock", async kitId => {
  return fetchData(`/kits/returnToStock/${kitId}`, "patch");
});
export const fetchMySamples = createAsyncThunk("sample/fetchMy", async params => {
  return fetchData(`/samples/getAll`, "get", {params});
});

//////images

export const addNewImage = createAsyncThunk("funcSamples/addNewSampleImages", async ({type, id, formdata}) => {
  const response = fetchData(`/${type}s/images/add/${id}`, "post", formdata);
  return response;
});

export const deleteImage = createAsyncThunk("funcSamples/deleteSampleImage", async ({type, imageId}) => {
  const response = fetchData(`/${type}s/images/remove/${imageId}`, "delete");
  return response;
});

//////
export const addNewSampleImages = createAsyncThunk("funcSamples/addNewSampleImages", async ({sampleId, formdata}) => {
  const response = fetchData(`/samples/images/add/${sampleId}`, "post", formdata);
  return response;
});

export const deleteSampleImage = createAsyncThunk("funcSamples/deleteSampleImage", async imageId => {
  const response = fetchData(`samples/images/remove/${imageId}`, "delete");
  return response;
});

export const addNewKitImages = createAsyncThunk("funcSamples/addNewKitImages", async ({kitId, formdata}) => {
  const response = fetchData(`/kits/images/add/${kitId}`, "post", formdata);
  return response;
});

export const deleteKitImage = createAsyncThunk("funcSamples/deleteKitImage", async imageId => {
  const response = fetchData(`kits/images/remove/${imageId}`, "delete");
  return response;
});

const userSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {
    deselectSample: state => {
      state.currentSample = null;
    },
    cleanUpCurrent: state => {
      state.current = null;
    },
    setMyCurrentSampleObj: (state, {payload}) => {
      state.currentSample = payload;
    },
    cleanUpMySamples: state => {
      state.mySamples = [];
    },
    setLocationHistory: (state, {payload}) => {
      state.locationHistory = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSample.fulfilled, (state, {payload}) => {
        state.currentSample = payload;
      })
      .addCase(fetchByQR.fulfilled, (state, {payload}) => {
        var type = Object.prototype.hasOwnProperty.call(payload, "sampleId") ? "sample" : "kit";
        state.current = {...payload, type};
      })
      .addCase(fetchByQR.rejected, (state, {error}) => {
        console.log("(fetchByQR.rejected payload :", error.message);
        getError("No such QR code in the database!");
        state.current = null;
      })
      .addCase(fetchMySamples.pending, state => {
        state.tableLoading = true;
      })
      .addCase(fetchMySamples.fulfilled, (state, {payload}) => {
        state.mySamples = payload.samples;
        state.tableLoading = false;
      })
      .addCase(fetchMySamples.rejected, state => {
        state.tableLoading = false;
      })
      .addCase(takeUnderReport.fulfilled, (state, {payload}) => {
        // state.current = null;

        getInfo(`Екземпляр ${payload.skuNumber} взято у п/з`);
      })
      .addCase(takeKitUnderReport.fulfilled, (state, {payload}) => {
        // state.current = null;
        getInfo(`Kit ${payload.skuNumber} взято у п/з`);
      })
      //// return sample
      .addCase(returnToStock.pending, state => {
        state.tableLoading = true;
      })
      .addCase(returnToStock.fulfilled, (state, {payload}) => {
        // state.currentSample = null;
        state.mySamples = state.mySamples.filter(samp => samp.sampleId !== payload.sampleId);
        state.tableLoading = false;
        getInfo(`Екземпляр ${payload.skuNumber} повернуто у сток`);
      })
      .addCase(returnToStock.rejected, state => {
        state.tableLoading = false;
      })
      ///return kit
      .addCase(returnKitToStock.pending, state => {
        state.tableLoading = true;
      })
      .addCase(returnKitToStock.fulfilled, (state, {payload}) => {
        state.currentSample = null;
        state.tableLoading = false;
        getInfo(`kitSKU №${payload.skuNumber} повернуто у сток`);
      })
      .addCase(returnKitToStock.rejected, state => {
        state.tableLoading = false;
      })

      ////////// images
      .addCase(addNewImage.fulfilled, (state, {payload}) => {
        state.current = {...state.current, images: payload};
        getInfo("Фото успішно завантажено");
      })
      .addCase(deleteImage.fulfilled, (state, {payload}) => {
        state.current = {...state.current, images: payload};
        getInfo("Фото успішно видалено");
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = userSlice;

export const {deselectSample, setMyCurrentSampleObj, cleanUpMySamples, cleanUpCurrent, setLocationHistory} = actions;

export default reducer;

export const getCurrentQrCode = state => state.sample.current?.qrCodeUrl || null;
export const getCurrentEntity = state =>
  state?.sample?.current
    ? {
        ...state.sample.current,
        createdAt: "[" + moment(state.sample?.current?.createdAt).format("DD.MM.YY  HH:mm:ss") + "]",
      }
    : null;
export const getMyCurrentSample = state => state.sample.currentSample;
// export const getCurrentKit = state => state.sample.currentKit;
export const getMySamples = state => state.sample.mySamples;
export const getTableLoading = state => state.sample.tableLoading;

export const getLocationHistory = state => state.sample.locationHistory;
