import {useEffect, useMemo, useRef} from "react";
import Carousel from "react-material-ui-carousel";

import {apiUrl} from "../../../api/baseURL";
import ImageButton from "../../../utilities/Buttons/ImageBtn copy";
import prevIcon from "../utils/Caret_Circle_Left.png";
import nextIcon from "../utils/Caret_Circle_Right.png";

import "./ImgSlider.scss";

function ImgSlider({
  imgs,
  positionCheck = null,
  className,
  width,
  height,
  showIndicators = true,
  currentPos = 0,
  onActiveImgChange = null,
}) {
  const images = useMemo(() => {
    return imgs
      ? imgs.map(img => {
          return {url: `${apiUrl}/${img}`};
        })
      : [];
  }, [imgs]);

  useEffect(() => {
    if (positionCheck) {
      positionCheck(imgs[currentPos]);
    }
  }, []);

  return (
    // <div className={`image_carusel_custom_style ${className}`}>
    <div style={{width: width, height: height}} className={`image_carusel_custom_style ${className || ""}`}>
      <Carousel
        classes={{item: "MuiCarouselItem-root"}}
        autoPlay={false}
        index={currentPos}
        height={height}
        // itemClass={className}
        indicators={showIndicators}
        navButtonsAlwaysVisible={true}
        swipe={false}
        navButtonsAlwaysInvisible={imgs?.length === 0}
        onChange={(now, previous) => {
          if (onActiveImgChange) {
            onActiveImgChange(now);
          }
          if (positionCheck) {
            positionCheck(imgs[now]);
          }
        }}
        fullHeightHover={true}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            background: "transparent",
            borderRadius: 0,
            // width: '100%',

            next: {
              background: "red !important",
              right: "100px",
              width: "10px",
              transform: " translateX(40px)",
            },
          },
        }}
        navButtonsWrapperProps={{
          style: {
            // bottom: '0',
            // top: 'unset'
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "3px", // 1
            color: " #88A5CF", // 3
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "#BFCDE2",
          },
        }}
        NextIcon={<img src={nextIcon} alt="next" style={{width: "1em", zIndex: "100"}} draggable="false" />}
        PrevIcon={<img src={prevIcon} alt="prev" style={{width: "1em"}} draggable="false" />}
        // NavButton={({onClick, className, style, next, prev}) => {
        //   return (
        //     <ImageButton
        //       src={next ? nextIcon : prevIcon}
        //       size={1}
        //       onClick={onClick}
        //       className={className}
        //       style={{...style, transform: `translateX(${next ? "40px" : "-40px"})`}}
        //     />
        //   );
        // }}
      >
        {images.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}

function Item(props) {
  return (
    <div className="caruselItem">
      <img src={props.item.url} alt="" draggable="false" />
    </div>
  );
}

export default ImgSlider;
