import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {
  cleanUpCurrent,
  cleanUpMySamples,
  getCurrentEntity,
  returnKitToStock,
  returnToStock,
  setMyCurrentSampleObj,
} from "../../slices/SampleSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import DateInput from "../../utilities/Inputs/DateInput";
import TextAreaInput from "../../utilities/Inputs/TextAreaInput";
import TextInput from "../../utilities/Inputs/TextInput";
import {ROUTES} from "../../utilities/routes";
import ImgBox from "../ImgBox/ImgBox";
import SkuTable, {SKU_TABLE_VARIANTS} from "../SkuList.js/SkuTable";
import Spinner from "../spinner/Spinner";

const RETURN_FORM_VARIANTS = Object.freeze({KIT: "kit", SAMPLE: "sample"});

const ReturnForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [curentKit, setCurrentKit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isReturned, setIsReturned] = useState(false);
  const [variant, setVariant] = useState(null);

  const current = useSelector(getCurrentEntity);
  const imageMass = useMemo(() => current?.images?.map(img => img.imageUrl) || [], [current]);

  useEffect(() => {
    if (current?.type === "sample") {
      // dispatch(setMyCurrentSampleObj(current));
      setVariant(RETURN_FORM_VARIANTS.SAMPLE);
    }
    if (current?.type === "kit") {
      setCurrentKit(current);
      setVariant(RETURN_FORM_VARIANTS.KIT);
    }
    setTimeout(() => {
      setLoading(false);
      // dispatch(deselectSample());
    }, 1000);
  }, [current?.type]);

  useEffect(() => {
    return () => {
      dispatch(setMyCurrentSampleObj(null));
    };
  }, []);

  const initialValues = useMemo(() => {
    return {
      skuNumber: current?.skuNumber || "",
      dateTo: current?.dateTo || "",
      name: current?.name || "",
      note: current?.note || "",
      collection: current?.collection || "",
      color: current?.color || "",
      kit: current?.kit?.skuNumber || "",
      manager: current?.manager ? current.manager.firstName + " " + current.manager.lastName : "",
      createdAt: current?.createdAt,
    };
  }, [current]);

  const onReturnToStock = () => {
    Swal.fire({
      title: ``,
      text: `Повернути SKU №: ${curentKit?.skuNumber || current?.skuNumber} з п/з`,
      icon: "question",
      confirmButtonText: "Так",
      showCancelButton: true,
      cancelButtonColor: "#A9A9A9",
    }).then(result => {
      if (result.isConfirmed) {
        if (curentKit) {
          dispatch(returnKitToStock(curentKit.kitId));
        } else {
          dispatch(returnToStock(current.sampleId));
        }
        setIsReturned(true);
      }

      // navigate(ROUTES.SAMPLES_INFO);
      // dispatch(cleanUpMySamples());
    });
  };

  const onBackBthClick = () => {
    navigate(ROUTES.SCANER);
    dispatch(cleanUpMySamples());
    dispatch(cleanUpCurrent());
  };

  return (
    <div className="form_wrapper">
      <div style={{marginBottom: "10px"}}>
        <h1 className="centered">{`${t("Return from subreport")} ${
          variant === RETURN_FORM_VARIANTS.SAMPLE ? "SKU" : "kitSKU"
        }`}</h1>
        <Formik
          initialValues={
            curentKit
              ? {
                  ...curentKit,
                  manager: curentKit?.manager ? curentKit.manager.firstName + " " + curentKit.manager.lastName : "",
                }
              : initialValues
          }
          validationSchema={Yup.object({})}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={() => {}}
        >
          <Form className="Samplies_MOB_Manager_form ">
            <div className="fc w100 jcc sku_block nowrap">
              <div className="inputs">
                <DateInput label={t("Підзвіт ДО:")} name="dateTo" readOnly={true} />
              </div>
              <div className="sample_photo_container">
                <ImgBox imgs={imageMass} width={"180px"} height={"180px"} />
              </div>
            </div>
            <div className="flexG fcCol jcc mb aife w100">
              <div className="fcCol jcsa aife gap0_7 w100">
                <div className="fc nowrap w100 jcsb">
                  {variant === RETURN_FORM_VARIANTS.SAMPLE && (
                    <TextInput
                      label={t("SKU №:")}
                      name={"skuNumber"}
                      width={"100%"}
                      readOnly
                      inputclassname="skuInput"
                      style={{width: "calc(100% - 11em)"}}
                    />
                  )}
                  {variant === RETURN_FORM_VARIANTS.KIT && (
                    <TextInput
                      label={t("kitSKU №:")}
                      name={"skuNumber"}
                      width={"100%"}
                      readOnly
                      inputclassname="skuInput"
                      style={{width: "calc(100% - 11em)"}}
                    />
                  )}
                  <TextInput name={"createdAt"} style={{width: "9.8em"}} readOnly />
                </div>
                {/* 
                <TextInput
                  label={t("nomination")}
                  name={"name"}
                  generalClassName={"w100"}
                  width={"100%"}
                  readOnly
                  inputclassname="skuInput"
                /> */}
                <TextAreaInput
                  label={t("nomination")}
                  name={"name"}
                  width={"100%"}
                  height="70px"
                  readonly={true}
                  inputclassname="skuInput"
                />
                {variant === RETURN_FORM_VARIANTS.SAMPLE && (
                  <>
                    <TextInput
                      label={t("Collection") + ":"}
                      name={"collection"}
                      width={"100%"}
                      readOnly
                      inputclassname="skuInput"
                    />
                    <TextInput
                      label={t("Color") + ":"}
                      name={"color"}
                      width={"100%"}
                      className="mb"
                      readOnly
                      inputclassname="skuInput"
                    />
                  </>
                )}
              </div>
              <TextInput
                label={t("Менеджер п/з:")}
                name={"manager"}
                width={"100%"}
                readOnly={true}
                inputclassname="skuInput"
                generalClassName={variant === RETURN_FORM_VARIANTS.KIT ? "mt3x" : "mt"}
              />
            </div>
          </Form>
        </Formik>
      </div>
      {loading ? <Spinner /> : <SkuTable selectable={false} variant={SKU_TABLE_VARIANTS.RETURN_FORM} />}

      <div className="fc jcsb contr_btns" style={{width: "100%", marginTop: "10px"}}>
        <FunctionButton
          name={isReturned ? "Returned from report" : "Return from report"}
          autoWidth
          onClick={onReturnToStock}
          disabled={isReturned}
          className="bigBtn"
        />
        <FunctionButton name={t("Back")} width={"125px"} onClick={onBackBthClick} className="bigBtn" />
      </div>
    </div>
  );
};

export default ReturnForm;
