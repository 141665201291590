import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";

import {apiUrl} from "../../api/baseURL";
import {
  cleanUpCurrent,
  cleanUpMySamples,
  deleteImage,
  deleteKitImage,
  deleteSampleImage,
  fetchByQR,
  getCurrentEntity,
  getLocationHistory,
} from "../../slices/SampleSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn copy";
import TextAreaInput from "../../utilities/Inputs/TextAreaInput";
import TextInput from "../../utilities/Inputs/TextInput";
import {ROUTES} from "../../utilities/routes";
import {getInfo} from "../../utilities/toasts";
import ImgBox from "../ImgBox/ImgBox";
import {SAMPLES_INFO_VARIANTS} from "../IndexPage/SamplesInfo";

import cameraIcon from "./src/8725454_camera_plus_icon.png";
import SwipeableTextMobileStepper from "./TestSlider";

const StyledSpeedDialAction = styled(SpeedDialAction)(({theme}) => ({
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // добавляем тень
  width: 56, // увеличиваем ширину
  height: 56, // увеличиваем высоту
  margin: "8px", // устанавливаем отступ между кнопками
  // backgroundColor: "#567db8bf",
  "& .MuiSpeedDialAction-staticTooltip, &.MuiSpeedDialAction-tooltipPlacementLeft, &.MuiSpeedDialAction-tooltipPlacementRight":
    {
      backgroundColor: "#ff0000", // изменяем цвет фона всплывающей подсказки
      color: "#ffffff", // изменяем цвет текста всплывающей подсказки
    },
}));

const SampleInfoForm = ({onCameraOpen}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationHistory = useSelector(getLocationHistory);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const handleOpenSpeedDial = () => setSpeedDialOpen(true);
  const handleCloseSpeedDial = () => setSpeedDialOpen(false);

  const location = useLocation();
  const qrUrl = location?.state?.qrUrl || null;

  const current = useSelector(getCurrentEntity);

  const imageMass = useMemo(() => current?.images?.map(img => img.imageUrl) || [], [current]);

  const [variant, setVariant] = useState(null);
  const [curentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (current) {
      setVariant(current.type === "sample" ? SAMPLES_INFO_VARIANTS.SAMPLE : SAMPLES_INFO_VARIANTS.KIT);
    }
  }, [current?.type]);

  const initialValues = useMemo(
    () => ({
      skuNumber: "",
      dateTo: "",
      name: "",
      collection: "",
      color: "",
      note: "",
      createdAt: "",
    }),
    [],
  );

  useEffect(() => {
    if (qrUrl && !current) getSampleInfoByQR(qrUrl);
  }, []);

  const getSampleInfoByQR = url => {
    if (url.includes(apiUrl)) {
      dispatch(fetchByQR(url));
    } else getInfo("Такого QR коду немає в базі");
  };

  const onBackBtnClick = () => {
    // if (locationHistory[1] !== ROUTES.SAMPLE_IMAGE_SCANER) {
    //   navigate(locationHistory[1]);
    // } else {
    dispatch(cleanUpCurrent());
    dispatch(cleanUpMySamples());
    navigate(ROUTES.SAMPLE_IMAGE_SCANER);
  };

  const onStepChange = step => {
    setCurrentImageIndex(step);
  };
  const onImageDelete = () => {
    // setSpeedDialOpen(false);
    Swal.fire({
      title: ``,
      text: `Ви впевнені?`,
      icon: "question",
      confirmButtonText: "Так",
      showCancelButton: true,
      cancelButtonColor: "#A9A9A9",
    }).then(result => {
      if (result.isConfirmed) {
        const imageId = current?.images[curentImageIndex].imageId;
        dispatch(deleteImage({type: current.type, imageId}));
      }
    });
  };

  return (
    <div>
      <Formik initialValues={current || initialValues} enableReinitialize>
        <Form className="Samplies_MOB_Manager_form " style={{gap: "0"}}>
          <div className=" fcCol gap aic jcc" style={{position: "relative", marginBottom: "2em"}}>
            <Box sx={{flexGrow: 1}}>
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{position: "absolute", bottom: "-10px", right: "-60px"}}
                icon={<SpeedDialIcon />}
                onClose={handleCloseSpeedDial}
                onOpen={handleOpenSpeedDial}
                open={speedDialOpen}
              >
                <StyledSpeedDialAction
                  key={"New"}
                  icon={<AddAPhotoIcon color="primary" />}
                  tooltipTitle={"Додати"}
                  tooltipOpen
                  onClick={() => {
                    setSpeedDialOpen(false);
                    onCameraOpen();
                  }}
                />
                {imageMass.length > 0 && (
                  <StyledSpeedDialAction
                    key={"Delete"}
                    icon={<DeleteForeverIcon sx={{color: "#8B0000"}} />}
                    tooltipTitle={"Видалити"}
                    tooltipOpen
                    onClick={onImageDelete}
                  />
                )}
              </SpeedDial>
            </Box>

            <SwipeableTextMobileStepper images={imageMass} onStepChange={onStepChange} showLast={true} />
          </div>

          <div className="flexG fcCol jcsa aife w100">
            <div className="fcCol jcsa aife w100">
              <div className="fc nowrap w100 jcsb">
                {variant === SAMPLES_INFO_VARIANTS.SAMPLE && (
                  <TextInput
                    label={t("SKU №:")}
                    name={"skuNumber"}
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                    style={{width: "calc(100% - 11em)"}}
                  />
                )}
                {variant === SAMPLES_INFO_VARIANTS.KIT && (
                  <TextInput
                    label={t("kitSKU №:")}
                    name={"skuNumber"}
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                    style={{width: "calc(100% - 11em)"}}
                  />
                )}
                <TextInput name={"createdAt"} style={{width: "9.8em"}} readOnly />
              </div>

              {/* <TextInput label={t("nomination")} name={"name"} width={"100%"} readOnly inputclassname="skuInput" /> */}
              <TextAreaInput
                label={t("nomination")}
                name={"name"}
                width={"100%"}
                height="70px"
                readonly={true}
                inputclassname="skuInput"
              />

              {variant === SAMPLES_INFO_VARIANTS.SAMPLE && (
                <>
                  <TextInput
                    label={t("Collection") + ":"}
                    name={"collection"}
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Color") + ":"}
                    name={"color"}
                    width={"100%"}
                    className="mb"
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Texture") + ":"}
                    name={"texture.textureName"}
                    width={"100%"}
                    className="mb"
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Material") + ":"}
                    name={"material.materialName"}
                    width={"100%"}
                    className="mb"
                    readOnly
                    inputclassname="skuInput"
                  />
                </>
              )}
            </div>
          </div>
          <div className="fc jcsb contr_btns" style={{bottom: "2em", right: "5px"}}>
            <FunctionButton name="Back" onClick={onBackBtnClick} className="bigBtn" />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SampleInfoForm;
