import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {cleanUpMySamples, getMyCurrentSample, setMyCurrentSampleObj} from "../../slices/SampleSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import TextAreaInput from "../../utilities/Inputs/TextAreaInput";
import TextInput from "../../utilities/Inputs/TextInput";
import {ROUTES} from "../../utilities/routes";

import SkuTable from "./SkuTable";

const SkuListPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentSample = useSelector(getMyCurrentSample);
  const brandManager =
    currentSample && `${currentSample.brandManager.firstName} ${currentSample.brandManager.lastName}`;
  const formikValues = {...currentSample, brandManager};

  const initialValues = useMemo(
    () => ({
      name: "",
      brandManager: "",
      note: "",
    }),
    [],
  );

  return (
    <div className="h100">
      <h1 className="centered mb">{t("SKU що у мене у підзвіті:")}</h1>
      <div className="form_wrapper" style={{height: "calc(100% - 2em)"}}>
        <SkuTable />
        <div style={{height: "200px"}}>
          <Formik
            initialValues={formikValues || initialValues}
            validationSchema={Yup.object({})}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={() => {}}
          >
            <Form className="fc jcfe g1">
              <TextInput
                label={t("Brand Manager")}
                name={"brandManager"}
                inputclassname="skuInput"
                disabled
                width="100%"
                style={{width: "100%"}}
              />
              <TextAreaInput
                label={t("nomination")}
                name={"name"}
                width={"100%"}
                height="70px"
                readonly={true}
                inputclassname="skuInput"
              />
              <TextAreaInput name={"note"} placeholder={t("Примітка")} height={"45px"} readonly />
              <FunctionButton
                name="Back"
                type="submit"
                className="bigBtn centered"
                onClick={() => {
                  dispatch(setMyCurrentSampleObj(null));
                  dispatch(cleanUpMySamples());
                  navigate(ROUTES.SCANER);
                }}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SkuListPage;
