/* eslint-disable simple-import-sort/imports */
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";

import "./style/List.scss";
import "./style/App.scss";
import "./style/Buttons.scss";
import "../../utilities/Inputs/utils/Inputs.scss";

import ErrorBoundary from "../../utilities/boundaries/errorBoundary/ErrorBoundary";
import RequireAuth from "../../utilities/boundaries/RequireAuth";
import IndexPage from "../IndexPage/IndexPage";
import LoginPage from "../Login/LoginPage";
import PasswordForm from "../Login/PasswordForm";
import CameraComponent from "../SamplesImages/CameraPhoto";
import SwipeableEdgeDrawer from "../SamplesImages/CameraDrawer";

function App() {
  const {t} = useTranslation();
  const {i18n} = useTranslation();
  const {token, user} = useSelector(state => state.user);

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  return (
    <Router>
      <div className="App">
        <div className="App__container">
          <div className="mobile-container">
            <Routes>
              <Route
                path="/ba3a/samples/*"
                element={
                  <RequireAuth>
                    <IndexPage />
                  </RequireAuth>
                }
              />

              <Route
                path="/"
                element={
                  <ErrorBoundary>
                    <Navigate to="/login" />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/login"
                element={
                  user && !token ? (
                    <ErrorBoundary>
                      <Navigate to="/password" />
                    </ErrorBoundary>
                  ) : (
                    <ErrorBoundary>
                      <LoginPage />
                    </ErrorBoundary>
                  )
                }
              />
              <Route
                path="/password"
                element={user && token ? <Navigate to="/ba3a/samples/qrread" /> : <PasswordForm />}
              />
              <Route
                path="/*"
                element={
                  <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
                    <p> {t("Under development")}</p>
                  </div>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
