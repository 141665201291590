import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
// import Flatpickr from "flatpickr";
import flatpickr from "flatpickr";
import {useField, useFormikContext} from "formik";
import moment from "moment";
import PropTypes from "prop-types";

import calendarIcon from "./utils/Calendar.png";
// import QuestionItem from "../../utilities/QuestionItem";
// import useInputsTooltip from "./hook/InputsTooltipService";
import {Ukrainian} from "./utils/ukrLocale";

import "flatpickr/dist/flatpickr.min.css";

export const SimpleDateInput = ({label, ...props}) => {
  const [field, meta] = useField(props);

  return (
    <div className="project_input" style={{position: "relative"}}>
      <div className="datePicker_withText">
        <label htmlFor={props.name} style={{width: "auto", marginRight: "5px"}}>
          {label}
        </label>
        <input {...props} {...field} type="date" style={{width: "9em"}} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" />
        {!props.readOnly && <img src={calendarIcon} alt="" draggable="false" />}
      </div>
      {meta.touched && meta.error ? <div className="error ">{meta.error}</div> : null}
    </div>
  );
};

export const DateInput = ({
  label,
  className = "",
  question,
  labelWrap,
  labelW,
  flatpikrConfig = {},
  isRowLayout = false,
  style = {},
  generalClassName = "",
  ...props
}) => {
  const [field, meta] = useField(props);
  const inputRef = useRef();
  const [date, setDateValue] = useState("");
  const {t, i18n} = useTranslation();
  const [flatpickrInstance, setInstanse] = useState(null);

  // const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);

  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    setInstanse(
      flatpickr(inputRef.current, {
        dateFormat: "d.m.Y",
        clickOpens: false,
        weekNumbers: true,
        locale: i18n.language === "en" ? "en" : Ukrainian,
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("in picker", typeof selectedDates[0]);
          setFieldValue(props.name, selectedDates[0].toJSON());
        },
        ...flatpikrConfig,
      }),
    );

    return () => {
      // Очистка экземпляра Flatpickr при размонтировании компонента
      setInstanse(null);
    };
  }, []);

  useEffect(() => {
    // console.log(field?.value);
    // console.log("type", typeof field?.value);

    if (typeof field?.value === "string" && field?.value !== "" && flatpickrInstance) {
      var d = flatpickr.formatDate(new Date(field?.value), "d.m.Y");
      setDateValue(d);
      // console.log("dateeeeeeeeeeee", d);
      if (d) flatpickrInstance.setDate(new Date(field.value), false);
    } else if ((!field?.value || field?.value === "") && flatpickrInstance) {
      setDateValue("");
      flatpickrInstance.setDate("", false);
    }
    // flatpickrInstance.setDate(flatpickr.formatDate(new Date(field.value), "d.m.Y"), false);
    // if (flatpickrInstance) {
    //   flatpickrInstance.setDate(flatpickr.formatDate(new Date(field.value), "d.m.Y"), false);
    //   setDateValue(flatpickr.formatDate(new Date(field?.value), "d.m.Y"));
    //   //   if (field?.value && field?.value !== "" && typeof field?.value === "object") {
    //   //     setDateValue(flatpickr.formatDate(field?.value, "d.m.Y"));
    //   //   } else if (field?.value && field?.value !== "") {
    //   //     flatpickrInstance.setDate(flatpickr.formatDate(new Date(field.value), "d.m.Y"), false);
    //   //     setDateValue(flatpickr.formatDate(new Date(field.value), "d.m.Y"));
    //   //   } else {
    //   //     setDateValue("");
    //   //   }
    //   // } else if (field?.value && field?.value !== "") {
    //   //   setDateValue(flatpickr.formatDate(new Date(field?.value), "d.m.Y"));
    // }
  }, [field.value, flatpickrInstance]);

  const inp = useMemo(() => {
    return (
      <input
        className="calendar"
        onFocus={e => e.preventDefault()}
        ref={inputRef}
        // {...props}
        // {...field}
        value={date}
        type="text"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        style={{width: "7em", cursor: "auto"}}
        readOnly={true} // Отключение возможности ввода текста, так как Flatpickr будет отвечать за выбор даты
        // onMouseEnter={question ? onMouseEnter : null}
        // onMouseLeave={question ? onMouseLeave : null}
      />
    );
  }, [date]);

  return (
    <div
      className={`project_input ${labelWrap ? "" : "labelWrap"} date ${generalClassName}`}
      style={{position: "relative"}}
    >
      <div
        className={`datePicker_withText ${className}`}
        style={{flexDirection: isRowLayout ? "row" : "column", ...style}}
      >
        <label htmlFor={props.name} style={{width: labelW || "auto", marginBottom: "0.5em"}} className={"autolabel"}>
          {typeof label === "string" ? t(label) : label}
        </label>
        {isRowLayout && inp}
        {!props.readOnly && (
          <div className={`datePicker_img_container ${meta.touched && meta.error ? " datePicker_error" : ""}`}>
            <img
              className="datePicker_img"
              src={calendarIcon}
              alt=""
              onClick={() => {
                flatpickrInstance && flatpickrInstance.open();
              }}
            />
          </div>
        )}
        {!isRowLayout && inp}

        {/* {question ? (
          <div className="question-date">
            <QuestionItem title={question} tooltipShown={tooltipShown} />
          </div>
        ) : null} */}
      </div>
      {/* {meta.touched && meta.error ? <div className="error error_dateInp">{meta.error}</div> : null} */}
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  props: PropTypes.array,
};

export default DateInput;
