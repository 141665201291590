import {ListItemText} from "@mui/material";

import GlobeIcon from "../../images/icons/globe.png";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";

import useLanguageSwitchService from "./useLanguageSwitchService";

import styles from "./LanguageSwitch.module.scss";

const LanguageSwitch = () => {
  const {onLanguageBtnClick} = useLanguageSwitchService();

  return (
    <button
      type={BUTTON_TYPES.BUTTON}
      onClick={onLanguageBtnClick}
      alt="language switcher button"
      className={styles.languageBtn}
    >
      <ListItemText primary={"Language"} />
      {/* <img alt="language switcher icon" src={GlobeIcon} draggable="false" className={styles.languageImg} /> */}
    </button>
  );
};

export default LanguageSwitch;
