import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Html5Qrcode} from "html5-qrcode";

import {apiUrl} from "../../api/baseURL";
import {fetchByQR, getCurrentEntity} from "../../slices/SampleSlice";
import {getCurrentUser, logout} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn copy";
import {ROUTES} from "../../utilities/routes";
import {getError, getInfo} from "../../utilities/toasts";
import {ROLES} from "../Login/LoginPage";

import ratateCameraImg from "./rotate-camera.png";

const QRcontainer = () => {
  // const [isEnabled, setEnabled] = useState(true);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isEnabled = true;
  const [qrMessage, setQrMessage] = useState("");

  const navigate = useNavigate();

  const [facingMode, setFacingMode] = useState(false);

  const currentUser = useSelector(getCurrentUser);
  const current = useSelector(getCurrentEntity);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  useEffect(() => {
    console.log("qrMessage", qrMessage);
    if (qrMessage && qrMessage !== "") {
      dispatch(fetchByQR(qrMessage));
    }
  }, [qrMessage]);

  useEffect(() => {
    if (current)
      if (
        current &&
        currentUser?.role.roleId === ROLES.BRAND_MANAGER &&
        current?.status === "Under Report" &&
        current?.brandManagerId === currentUser?.userId
      ) {
        navigate(ROUTES.RETURN_FORM);
      } else {
        navigate(ROUTES.SAMPLES_INFO, {state: {qrUrl: qrMessage}});
      }
  }, [current]);

  const scannerWidth = 0.8 * windowWidth;
  const scannerHeight = 0.65 * windowHeight;

  useEffect(() => {
    // const config = {fps: 10, qrbox: {width: windowWidth * 0.8, height: windowHeight * 0.7}, aspectRatio: 0.5};
    const config = {
      fps: 10,
      qrbox: {width: scannerWidth, height: scannerHeight},
      aspectRatio: scannerWidth / scannerHeight,
    };

    const html5QrCode = new Html5Qrcode("qrCodeContainer2");

    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(() => console.log("Scaner stop"))
          .catch(() => console.log("Scaner error"));
      }
    };

    const qrCodeSuccess = decodedText => {
      console.log("succ");
      if (!decodedText.includes(apiUrl)) {
        getInfo("Такого QR коду немає в базі");
        return;
      }
      setQrMessage(decodedText);
      qrScanerStop();
    };

    if (isEnabled) {
      Html5Qrcode.getCameras()
        .then(cameras => {
          const camerasLength = cameras?.length;
          if (cameras && camerasLength) {
            const selectedCameraId = facingMode ? cameras[0]?.id : cameras[camerasLength - 1]?.id;
            html5QrCode.start(selectedCameraId, config, qrCodeSuccess).catch(() => {
              alert("smt wrong");
            });
            setQrMessage("");
          } else {
            alert("No cameras found.");
          }
        })
        .catch(error => {
          console.error(error);
          getError(t("Scaner access error"));
          getError(error.message);
        });
    } else {
      qrScanerStop();
    }

    return () => {
      qrScanerStop();
    };
  }, [isEnabled, facingMode]);

  function onLogout() {
    // navigate(ROUTES.SAMPLES_INFO);
    dispatch(logout());
    getInfo(t("Logged out"));
  }

  return (
    <div className="fc aic jcc h100">
      <div className="centeredFull qrContainer fc aic jcc">
        <div id="qrCodeContainer2" style={{width: 0.8 * windowWidth, height: 0.65 * windowHeight}} />
        <div className="fc g1 mt3x aic jcc">
          <ImageButton src={ratateCameraImg} onClick={() => setFacingMode(facingMode => !facingMode)} size={2} />
          <FunctionButton name="LogOut" onClick={onLogout} className="centered" />
        </div>
      </div>
    </div>
  );
};

export default QRcontainer;
