import * as React from "react";
import {useEffect, useState} from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {Button, MobileStepper} from "@mui/material";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";

import {apiUrl} from "../../api/baseURL";
import ImgSlider from "../ImgBox/slider/NewImgSlider";
import Modal from "../Modal/Modal";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({images, onStepChange = null, showLast = false}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images?.length || 0;

  const [modal, setModal] = React.useState(false);

  useEffect(() => {
    if (showLast) {
      setActiveStep(images?.length - 1);
    }
  }, [images]);

  const handleNext = () => {
    if (activeStep < maxSteps) setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep !== 0) setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (onStepChange) onStepChange(activeStep);
  }, [activeStep]);

  const handleSwipe = event => {
    const deltaX = event.changedTouches[0].clientX - touchStartX;
    if (deltaX > 50) {
      handleBack();
    } else if (deltaX < -50) {
      handleNext();
    }
  };
  let touchStartX = 0;

  return (
    <Box sx={{flexGrow: 1, position: "relative"}}>
      <div
        className="slides"
        onClick={() => (images.length === 0 ? null : setModal(true))}
        style={{width: "250px", height: "250px"}}
        onTouchStart={e => (touchStartX = e.touches[0].clientX)}
        onTouchEnd={handleSwipe}
      >
        {/* && imgPath !== null */}
        {images?.map((imgPath, index) => {
          if (imgPath != null)
            return (
              <div key={imgPath} className={index === activeStep ? "slide active" : "slide"}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 255,
                      display: "block",
                      maxWidth: 250,
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={`${apiUrl}/${imgPath}`}
                    alt={imgPath}
                  />
                ) : null}
              </div>
            );
          else {
            return null;
          }
        }) || null}
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{justifyContent: "center", background: "transparent"}}
        // nextButton={
        //   <Button
        //     size="small"
        //     onClick={handleNext}
        //     disabled={activeStep === maxSteps - 1}
        //     sx={{position:"absolute"}}
        //   >
        //     {/* Next */}
        //     {theme.direction === 'rtl' ? (
        //       <KeyboardArrowLeft />
        //     ) : (
        //       <KeyboardArrowRight />
        //     )}
        //   </Button>
        // }
        // backButton={
        //   <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
        //     {theme.direction === 'rtl' ? (
        //       <KeyboardArrowRight />
        //     ) : (
        //       <KeyboardArrowLeft />
        //     )}
        //     {/* Back */}
        //   </Button>
        // }
      />
      <div className="stepButtons">
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
        </Button>
        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          <KeyboardArrowRight />
        </Button>
      </div>

      <Modal active={modal} setActive={setModal}>
        {images?.length > 0 && (
          <div className={"sample_img_preview_carusel__container"}>
            <ImgSlider imgs={images} className={"sample_img_preview_carusel"} currentPos={activeStep} />
          </div>
        )}
      </Modal>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
