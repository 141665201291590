import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import QrScanner from "qr-scanner";

import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn copy";
import {getInfo} from "../../utilities/toasts";

import QrFrame from "./kvadro.png";
import ratateCameraImg from "./rotate-camera.png";

import "./scaner.scss";
const NewQrScaner = ({stop, setScannedResult}) => {
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState(null); // Default camera: "environment"
  const [availableCameras, setAvailableCameras] = useState([]);

  const [resultMessage, setResultMessage] = useState("");

  // const [scane]
  // Result
  // const [scannedResult, setScannedResult] = useState("");

  useEffect(() => {
    if (stop) scanner?.current?.stop();
  }, [stop]);
  // Success
  const onScanSuccess = result => {
    // 🖨 Print the "result" to browser console.

    // ✅ Handle success.
    // 😎 You can do whatever you want with the scanned result.
    // setScannedResult(result?.data);
    if (result?.data === resultMessage) {
      return;
    }
    if (result?.data) {
      if (result?.data.includes("in.ua")) {
        setResultMessage(result?.data.replace("in.ua", "com.pl"));
      } else {
        setResultMessage(result?.data);
      }
    }

    // scanner?.current?.stop();
  };

  useEffect(() => {
    if (resultMessage && resultMessage !== "") {
      setScannedResult(resultMessage);
    }
  }, [resultMessage]);

  // Fail
  const onScanFail = err => {
    // 🖨 Print the "err" to browser console.
    //   console.log("err", err);
  };

  useEffect(() => {
    const camera = localStorage.getItem("cameraId");
    if (videoEl?.current && !scanner.current) {
      QrScanner.listCameras(true).then(cameras => {
        setAvailableCameras(cameras);
        console.log("cameras", cameras);

        if (cameras.length > 0) {
          setSelectedCamera(camera || cameras[0]?.id); // Select the first camera by default
        }
        // getInfo("camera restart");
        // 👉 Instantiate the QR Scanner
        scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
          cameraId: camera || cameras[0]?.id,
          onDecodeError: onScanFail,
          // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
          // preferredCamera: "environment",
          // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
          //   highlightScanRegion: true,
          // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
          highlightCodeOutline: true,
          // maxScansPerSecond: 1
          // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
          //   overlay: qrBoxEl?.current || undefined,
        });

        // 🚀 Start QR Scanner
        scanner?.current
          ?.start()
          .then(() => {
            setQrOn(true);
            scanner.current.setCamera(camera || cameras[0]?.id);
          })
          .catch(err => {
            if (err) setQrOn(false);
          });
      });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (
      videoEl?.current &&
      selectedCamera != null &&
      scanner?.current &&
      selectedCamera !== availableCameras[0]?.id &&
      selectedCamera !== localStorage.getItem("cameraId")
    ) {
      getInfo("camera change");
      localStorage.setItem("cameraId", selectedCamera);
      scanner.current.setCamera(selectedCamera);
    }
  }, [selectedCamera]);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");
  }, [qrOn]);

  return (
    <div className="qr-reader">
      {/* QR */}
      <video ref={videoEl}></video>
      {/* <div ref={qrBoxEl} className="qr-box">
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={256}
            height={256}
            className="qr-frame"
          />
        </div> */}

      {/* Show Data Result if scan is success */}
      {/* {scannedResult && (
          <p
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 99999,
              color: "white",
            }}
          >
            Scanned Result: {scannedResult}
          </p>
        )} */}
      {/* <div className='camera_select_container '> */}
      {availableCameras.length > 0 && (
        <select className="camera_select" value={selectedCamera} onChange={e => setSelectedCamera(e.target.value)}>
          {availableCameras.map(camera => (
            <option key={camera?.id} value={camera?.id}>
              {camera.label}
            </option>
          ))}
        </select>
      )}
      {/* </div> */}
    </div>
  );
};

// const NewQrScaner = () => {

// };

export default NewQrScaner;
