import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {getUser, login as loginFunc} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {PasswordInput} from "../../utilities/Inputs/PasswordInput";
import TextInput from "../../utilities/Inputs/TextInput";
import {ROUTES} from "../../utilities/routes";
import {getError, getInfo} from "../../utilities/toasts";

import logo from "./src/Logo.png";

import "./src/login.scss";

export const ROLES = Object.freeze({BRAND_MANAGER: 1, SUPER_USER: 3});

const LoginPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {token, user} = useSelector(state => state.user);
  const role = user?.role?.roleId;

  useEffect(() => {
    if (token && user && role === ROLES.BRAND_MANAGER) {
      getInfo(t("Authorized"));
      navigate(ROUTES.SCANER);
    }
    if (token && user && role === ROLES.SUPER_USER) getError(t("Super User has no access to the Mobile App"));
  }, [token, user?.role?.roleId]);

  const onLogin = values => {
    const {samplLogin, samplPassword} = values;

    dispatch(loginFunc({email: samplLogin, password: samplPassword})).then(() => dispatch(getUser()));
  };

  return (
    <div className="login_page">
      <div className="navbar__logo">
        <img alt="" src={logo} draggable="false" />
        <h1>ERP ’ba3a’ </h1>
      </div>
      <div className="login_page__container">
        <h1 className="centered">Підзвіт Зразків</h1>
        <Formik
          initialValues={{samplLogin: "", samplPassword: ""}}
          validationSchema={Yup.object().shape({
            samplLogin: Yup.string().required(t("Enter login")),
            samplPassword: Yup.string().required(t("Enter password")),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={onLogin}
        >
          {({errors}) => (
            <Form className="login_form__container">
              <TextInput
                label={"login"}
                name={"samplLogin"}
                autoComplete="login-erp-samplies"
                generalClassName={"login_input_container" + (errors.samplLogin ? " login_input_container__error" : "")}
                showErrorMessage={false}
              />
              <PasswordInput label={"password"} name={"samplPassword"} autoComplete="parrw-erp-samplies" />
              <div className="fc jcsb contr_btns" style={{width: "100%"}}>
                <FunctionButton name="Вхід" type="submit" />
                <FunctionButton
                  name="Скасувати"
                  onClick={() => {
                    getInfo("toast");
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
