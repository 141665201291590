import React from "react";

import logo from "../Login/src/Logo.png";
const AppLogo = () => {
  return (
    <div className="app__logo">
      <img alt="" src={logo} draggable="false" />
    </div>
  );
};

export default AppLogo;
