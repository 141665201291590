import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";

import {fetchByQR, getCurrentEntity} from "../../slices/SampleSlice";
import {getCurrentUser, logout} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn copy";
import {ROUTES} from "../../utilities/routes";
import {getInfo} from "../../utilities/toasts";
import {ROLES} from "../Login/LoginPage";

import NewQrScaner from "./NewQrScaner";

const QRReaderContainer = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [qrMessage, setQrMessage] = useState("");
  const [scanerStop, setScanerStop] = useState(false);

  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUser);
  const current = useSelector(getCurrentEntity);

  const fetchInfo = () => {
    dispatch(fetchByQR(qrMessage));
  };
  const debouncedFetch = debounce(fetchInfo, 100);

  useEffect(() => {
    if (qrMessage && qrMessage !== "") {
      debouncedFetch();
    }
  }, [qrMessage]);

  useEffect(() => {
    if (current) {
      setScanerStop(true);
      if (
        current &&
        currentUser?.role.roleId === ROLES.BRAND_MANAGER &&
        current?.status === "Under Report" &&
        current?.brandManagerId === currentUser?.userId
      ) {
        navigate(ROUTES.RETURN_FORM);
      } else {
        navigate(ROUTES.SAMPLES_INFO, {state: {qrUrl: qrMessage}});
      }
    }
  }, [current]);

  function onLogout() {
    // navigate(ROUTES.SAMPLES_INFO);
    dispatch(logout());
    getInfo(t("Logged out"));
  }

  return (
    <div className="fc aic jcc h100 qrContainer">
      <div className="centeredFull   aic jcc" style={{flex: "auto"}}>
        <div style={{height: "80vh"}}>
          <NewQrScaner setScannedResult={setQrMessage} stop={scanerStop} />
        </div>
        <div className="qr-reader-title">Сканер підзвіту</div>
        <div className="fc g1 aic jcc" style={{position: "absolute", bottom: "1em", right: "5px"}}>
          {/* <ImageButton src={ratateCameraImg} onClick={() => setFacingMode(facingMode => !facingMode)} size={2} /> */}
          <FunctionButton name="LogOut" onClick={onLogout} className="centered bigBtn" />
        </div>
      </div>
    </div>
  );
};

export default QRReaderContainer;
