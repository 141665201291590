import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {changePassword} from "../../slices/UserSlice";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {PasswordInput} from "../../utilities/Inputs/PasswordInput";
import TextInput from "../../utilities/Inputs/TextInput";

const PasswordForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {user} = useSelector(state => state.user);

  const initialValues = useMemo(() => {
    return {
      newSamplPassword: "",
      repeatSamplPassword: "",
    };
  }, []);

  return (
    <div className="login-form__container fc jcc  h100 w100">
      <div className="login_form w100">
        <label className="label login_form_label">{t("Password change")}</label>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            newSamplPassword: Yup.string().required(t("Enter new password")),
            repeatSamplPassword: Yup.string().test("", t("Doesn`t match"), function (item) {
              return this.parent.newSamplPassword === item;
            }),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={values => {
            dispatch(changePassword({userId: user, password: values.newSamplPassword}));
          }}
        >
          {({errors}) => (
            <Form className="login_form__container">
              <TextInput
                type="password"
                generalClassName={
                  "login_input_container" + (errors.newSamplPassword ? " login_input_container__error" : "")
                }
                label={t("New password")}
                name="newSamplPassword"
                showErrorMessage={false}
                autoComplete="new-password-erp-samplies"
              />
              <PasswordInput
                label={t("Confirm passsword")}
                name="repeatSamplPassword"
                autoComplete="new-password-erp-samplies-repeat"
              />
              {errors.repeatSamplPassword ? (
                <div className="error" style={{bottom: "0", right: "0", top: "auto"}}>
                  {errors.repeatSamplPassword}
                </div>
              ) : null}
              <FunctionButton name={t("Confirm for entire form")} type={BUTTON_TYPES.SUBMIT} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordForm;
