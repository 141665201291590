// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-reader {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: calc(100% - 3em);
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}
.camera_select {
  height: 3em;
  padding: 0.5em 0;
  outline: none;
}

.qr-reader-title {
  position: absolute;
  top: calc(80vh - 2.5em);
  right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/QRReader/scaner.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEE;EACE,WAAA;EACA,wBAAA;EACA,iBAAA;AACJ;;AAEE;EACE,sBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,QAAA;EACA,4CAAA;AACJ;;AAEE,qCAAA;AACA;EACE;IACE,WAAA;EACJ;AACF;AAEE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;AAAJ;;AAQE;EACE,kBAAA;EACA,uBAAA;EACA,UAAA;AALJ","sourcesContent":[".qr-reader {\n    width: 100%;\n    height: 100%;\n    margin: 0 auto;\n    position: relative;\n  }\n  \n  .qr-reader video {\n    width: 100%;\n    height: calc(100% - 3em);\n    object-fit: cover;\n  }\n  \n  .qr-reader .qr-box {\n    width: 100% !important;\n    left: 0 !important;\n  }\n  \n  .qr-reader .qr-frame {\n    position: absolute;\n    fill: none;\n    left: 50%;\n    top: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n  \n  /* Media Queries for mobile screens */\n  @media (max-width: 426px) {\n    .qr-reader {\n      width: 100%;\n    }\n  }\n  \n  .camera_select{\n    height: 3em;\n    padding: 0.5em 0;\n    outline: none;\n  }\n  .camera_select_container{\n    // display: flex;\n    // justify-content: center;\n    // width: 100%;\n  }\n\n  .qr-reader-title{\n    position: absolute;\n    top: calc(80vh - 2.5em);\n    right: 5px\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
