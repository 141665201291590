import React from "react";

import {getError} from "../../toasts";

export default class ErrorBoundaryForQRContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    // getError("Scaner error");
    getError(errorInfo);
    console.error(error, errorInfo);
    this.setState({hasError: true});
  }

  render() {
    if (this.state.hasError) {
      return <div>You must grant access to your camera to use the App!</div>;
    }
    return this.props.children;
  }
}
