// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigationPanel a {
  list-style: none;
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: rgb(57, 63, 72);
}`, "",{"version":3,"sources":["webpack://./src/Components/Navigation/navigation.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,qBAAA;EAEA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;AADJ","sourcesContent":[".navigationPanel {\n  a {\n    list-style: none;\n    text-decoration: none;\n\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 500;\n    font-size: 13px;\n    color: rgb(57, 63, 72);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
