import {LANG_FORM_ID, LANG_FORM_KEY, LANGUAGES} from "./constants";

export default function makeSwalLanguageHtml(lang) {
  return `<form class="fc jcsa" id=${LANG_FORM_ID}>${Object.keys(LANGUAGES)
    .map(
      languageKey =>
        `<label><input type="radio" key=${languageKey} name=${LANG_FORM_KEY} value=${LANGUAGES[languageKey].value} ${
          lang === LANGUAGES[languageKey].value ? "checked" : ""
        } /> ${LANGUAGES[languageKey].name}</label>`,
    )
    .join(" ")}</form>`;
}
