import React from "react";
import ReactDOM from "react-dom/client";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";

import App from "./Components/App/App";
import i18n from "./i18n"; // Import the i18n configuration
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-center" hideProgressBar />
    </Provider>
  </I18nextProvider>,
);

// for checking the language at initialization
const savedLanguage = localStorage.getItem("language");
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
} else {
  const defaultLanguage = "en";
  i18n.changeLanguage(defaultLanguage);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
