import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import {addNewImage, addNewKitImages, addNewSampleImages, getCurrentEntity} from "../../slices/SampleSlice";
import {getError, getInfo} from "../../utilities/toasts";

import doneIcon from "./src/done.png";
import restartIcon from "./src/restart.png";

import "./src/camera.scss";
import { toast } from "react-toastify";
const CameraComponent = ({open, setOpen}) => {
  const [error, setError] = useState();
  const [isEnabled, setEnabled] = useState(false);
  const [facing, setFacing] = useState("environment");
  const [imageSrc, setImageSrc] = useState(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const canvasRef = useRef(null);

  const startStream = async () => {
    // getInfo("start strem")
    const devices = await navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => {
          device.kind !== "videoinput";
        });
        return videoDevices;
      })
      .catch(err => {
        getError(err);
      });

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode:  facing,
          deviceId: {
            exact: localStorage.getItem("cameraId") || devices[0]?.deviceId,
          },
        },
      })
      .then(stream => {
        streamRef.current = stream;
        videoRef.current.srcObject = streamRef.current;
        videoRef.current.onloadedmetadata = () => videoRef.current.play();
      })
      .catch(err => {
        setError(err.name);
      });
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  };

  const makePhoto = () => {
    // const videoWidth = videoRef.current.scrollWidth;
    // const videoHeight = videoRef.current.scrollHeight;
    // canvasRef.current.width = videoWidth;
    // canvasRef.current.height = videoHeight;
    // if (facing === "user") {
    //   const context = canvasRef.current.getContext("2d");
    //   context.scale(-1, 1);
    //   context.drawImage(videoRef.current, 0, 0, -videoWidth, videoHeight);
     
    // } else {
    //   canvasRef.current.getContext("2d").drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    // }
    // const imgSrc = canvasRef.current.toDataURL("image/jpeg");
    // setImageSrc(imgSrc); 
    // setEnabled(!isEnabled);
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    
    // Установим размеры canvas равными размерам видео
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;
  
    const context = canvasRef.current.getContext("2d");
  
    // Очищаем canvas
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  
    // Рисуем изображение из видео на canvas
    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
  
    const imgSrc = canvasRef.current.toDataURL("image/jpeg");
    setImageSrc(imgSrc);
    setEnabled(false);
  };

  const deletePhoto = () => {
    console.log("delete");
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.clearRect(0, 0, -canvasRef.current.width, canvasRef.current.height);
    setImageSrc(null);
   
  };

  const downloadPhoto = () => {
    const link = document.createElement("a");
    link.download = "photo.png";
    link.href = canvasRef.current.toDataURL("image/png");
    link.click();
  };

  useEffect(() => {
    setError(null);
    stopStream();
    if (isEnabled) startStream();
  }, [isEnabled, facing]);


  useEffect(() => {
    if (open && !imageSrc) {
      setEnabled(true);
    } else{
      setEnabled(false)
    }
  }, [open]);

  const dispatch = useDispatch();

  const current = useSelector(getCurrentEntity);

  const uploadPhoto = async () => {
    var formdata = new FormData();
    const blobData = dataURItoBlob(imageSrc);
    formdata.append("image", blobData);

    const dataObj = {type: current.type, id: current[current.type === "sample" ? "sampleId" : "kitId"], formdata};

    dispatch(addNewImage(dataObj)).then(() => {
      deletePhoto()
      setOpen(false);
    });
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  }

  return (
    <div>
      <div style={{position: "relative", width: "100%", height: "0", paddingTop: "80vh"}}>
        <video
          className={facing === "user" ? "mirror" : ""}
          playsInline
          muted
          autoPlay
          ref={videoRef}
          style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", objectFit: "contain"}}
        ></video>
        <canvas
          ref={canvasRef}
          style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", objectFit: "contain"}}
        ></canvas>
        {error && <div className="error">{error}</div>}

        <div className="camera_btn_container">
          <button onClick={imageSrc ? uploadPhoto : makePhoto} className="photo-button">
            {imageSrc ? <img src={doneIcon} /> : null}
          </button>
        </div>
        {imageSrc ? (
          <button onClick={() => {deletePhoto(); setEnabled(true);}} className="photo-button restart">
            <img src={restartIcon}></img>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CameraComponent;
