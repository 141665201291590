import {useEffect, useMemo, useRef, useState} from "react";
import {useField, useFormikContext} from "formik";

import closeEye from "../../images/icons/closeEye.png";
import openEye from "../../images/icons/openEye.png";
import ImageButton from "../Buttons/ImageBtn copy";

export const PasswordInput = ({label, ...props}) => {
  const [hide, setHide] = useState(true);
  const inputRef = useRef();

  const type = useMemo(() => (hide ? "password" : "text"), [hide]);

  const [field, meta] = useField(props);
  const {handleChange} = useFormikContext();

  function onToggleShowPasswordBtnClick() {
    setHide(hide => !hide);
  }

  // const onChange = e => {
  //   if (e.target.value.length === 0) {
  //     inputRef.current.setAttribute("readonly", true);
  //     setTimeout(() => {
  //       inputRef.current.removeAttribute("readonly");
  //     }, 100);
  //   }
  //   handleChange(e);
  // };

  // const onFocus = () => {
  //   inputRef.current.removeAttribute("readonly");
  //   if (inputRef.current.value) {
  //     setTimeout(() => {
  //       inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
  //     }, 0);
  //   }
  // };

  return (
    <div
      className={"login_input_container " + (meta.touched && meta.error ? "login_input_container__error" : "")}
      // onClick={() => {
      //   inputRef.current.focus();
      // }}
    >
      <label>{label}</label>
      <input
        {...props}
        {...field}
        // onChange={onChange}
        // readOnly={true}
        type={type}
        // autoComplete="new-password-erp-fe"
        // onFocus={onFocus}
      />
      {/* <div className="hidden"></div> */}
      <ImageButton src={hide ? closeEye : openEye} className={"showPaswBtn"} onClick={onToggleShowPasswordBtnClick} />
      {/* {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null} */}
    </div>
  );
};
