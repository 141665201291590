import {toast} from "react-toastify";

export const getError = error => {
  toast.error(error, {autoClose: 1500, position: "top-center"});
};
export const getInfo = text => {
  toast.info(text, {autoClose: 1000, position: "top-center"});
};

export const getWarn = text => {
  toast.warning(text, {autoClose: 1000, position: "top-center"});
};

export const getSuccess = text => {
  toast.success(text, {autoClose: 1000, position: "top-center"});
};
