import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../debounced";

// import QuestionItem from "../../utilities/QuestionItem";

// import useInputsTooltip from "./hook/InputsTooltipService";

const TextInput = ({
  label,
  width = "100%",
  className = "",
  generalClassName = "",
  inputclassname = "",
  question = null,
  func = null,
  autolabel,
  labelWrap,
  labelStyles,
  handleInputChange = () => {},
  disabled = false,
  placeholder = "",
  hasTitle = false,
  showErrorMessage = true,
  ...props
}) => {
  const [field, meta] = useField(props);
  // const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip(field);

  const {handleChange} = useFormikContext();

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    handleChange(e);
    handleInputChange(e);
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };

  return (
    <div className={`project_input w100 ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={props.style}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={"autolabel"}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          <input
            {...props}
            {...field}
            className={inputclassname}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            value={field.value || ""}
            onChange={onChange}
            style={{width: "100%"}}
            // onMouseEnter={question ? onMouseEnter : null}
            // onMouseLeave={question ? onMouseLeave : null}
            title={hasTitle && field.value ? field.value : null}
            onFocus={e => e.preventDefault()}
          />
        </div>
        {showErrorMessage && meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {/* {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null} */}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  handleInputChange: PropTypes.func,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasTitle: PropTypes.bool,
  props: PropTypes.array,
};

export default TextInput;
