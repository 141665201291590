import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

import {LANG_FORM_KEY} from "./constants";
import makeSwalLanguageHtml from "./makeSwalLanguageHtml";

const useLanguageSwitchService = () => {
  const {t, i18n} = useTranslation();

  function onLanguageBtnClick() {
    Swal.fire({
      title: t("Select the app language:"),
      html: makeSwalLanguageHtml(i18n.language),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: t("Yes, change it!"),
      cancelButtonText: t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        const langFormCheckedEl = document.querySelector(`input[name=${LANG_FORM_KEY}]:checked`);
        console.log("langFormEl :", langFormCheckedEl);
        i18n.changeLanguage(langFormCheckedEl.value);
        Swal.fire({
          title: t("Changed!"),
          text: t("App language has been changed."),
          icon: "success",
        });
      }
    });
  }

  return {onLanguageBtnClick};
};

export default useLanguageSwitchService;
