import {useState} from "react";
import PropTypes from "prop-types";

import Modal from "../Modal/Modal";

import ImgSlider from "./slider/NewImgSlider";

import "./ImgBox.scss";

const ImgBox = ({imgs, classNames, width, height, onActiveImgChange, label}) => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal active={modal} setActive={setModal}>
        {imgs?.length > 0 && (
          <div className={"sample_img_preview_carusel__container"}>
            <ImgSlider imgs={imgs} className={"sample_img_preview_carusel"} />
          </div>
        )}
      </Modal>
      {label && <div className="label imgBox__label">{label}</div>}
      <div className={"imgBox " + (classNames || "")}>
        <div className="imgBox__container" onClick={() => (imgs.length === 0 ? null : setModal(true))}>
          {imgs?.length > 0 && (
            <ImgSlider
              width={width}
              height={height}
              imgs={imgs}
              onActiveImgChange={onActiveImgChange}
              showIndicators={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ImgBox.propTypes = {
  imgs: PropTypes.array,
  classNames: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onActiveImgChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};

export default ImgBox;
